<template>
  <div>
    <form-summary
      v-if="!isValid"
      class="form-errors alert alert-danger"
      :validator="$v.form"
      :attributes="attributes"
    />

    <CLink
      @click="mapModal = true"
      color="primary"
      class="mb-2 d-block"
    >
      <CIcon name="cil-location-pin" />
      Show on Map
    </CLink>

    <zip-codes
      ref="resZipCodes"
      :active="false"
      :zip_codes.sync="form.zip_codes"
      :restaurant_zip_code="form.restaurant_postcode"
      :restaurant_country="form.countryId"
      :restaurant_lat="form.resto_lat"
      :restaurant_lng="form.resto_lng"
      :delivery_distances.sync="form.delivery_distances"
    />

    <CModal v-if="mapModal"
      :show.sync="mapModal"
      :closeOnBackdrop="true"
      :centered="false"
      size="xl"
      color="dark"
      title="Restaurant Delivery Areas"
    >
      <map-restaurant-delivery-areas
        :zip_codes.sync="form.zip_codes"
        :restaurant_name.sync="form.restaurant_name"
        :restaurant_lat.sync="form.resto_lat"
        :restaurant_lng.sync="form.resto_lng"
        @add:zip="(zip) => $refs.resZipCodes.addZipCodes(zip)"
      />

      <template #footer>
        <CButton @click="mapModal = false" color="dark">Close</CButton>
      </template>
    </CModal>
  </div>

</template>

<script>
import { validationMixin } from "vuelidate"
import { required } from "vuelidate/lib/validators"

export default {
  name: 'RestaurantWizardStep5',
  components: {
    ZipCodes: () => import('@/components/ZipCodes'),
    MapRestaurantDeliveryAreas: () => import('@/components/MapRestaurantDeliveryAreas'),
  },
  data() {
    return {
      form: {
        zip_codes: []
      },
      mapModal: false,

      // Vuelidate-error-extractor
      attributes: {
        zip_codes: 'Delivery region',
      },
    }
  },

  mixins: [validationMixin],
  validations: {
    form: {
      zip_codes: { required },
    }
  },

  async mounted() {
    this.form = this.getForm();

    this.$nextTick(function () {
      this.$emit("loaded");
    });
  },

  computed: {
    isValid() { return !this.$v.form.$invalid },
  },


  methods: {
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      return !(field.$invalid);
      // return !(field.$invalid || field.$model === "");
    },

    validate() {
      this.$v.$touch()
    },

    getForm() {
      const storedForm = localStorage.getItem('pending-restaurant');

      if (storedForm) {
        var form = JSON.parse(storedForm);
        form.zip_codes = form.zip_codes ?? [];

        form.delivery_distances = form.delivery_distances || {
          fee_enabled: null,
          based_fee: null,
          threshold_meter: null,
          max_distance_meter: null,
          max_distance_enabled: false,
        };
        return form;
      } else {
        this.$emit("reset");
      }
    },

    async next() {
      if (!this.isValid || !this.$refs.resZipCodes.isValid) {
        window.scroll({top: 180, behavior: 'smooth'});
        this.validate();
        return;
      }

      localStorage.setItem('pending-restaurant', JSON.stringify(this.form));
      this.$emit("next", true);
    },

    async previous() {
      localStorage.setItem('pending-restaurant', JSON.stringify(this.form));
      this.$emit("previous", true);
    },
  }
}
</script>
